import { Children } from "react";
import clsx from "clsx";

export function Navbar({ className = "", children }) {
  return (
    <nav
      className={clsx(
        "flex items-center justify-between space-x-4 w-full select-none z-10",
        className,
      )}
    >
      {children}
    </nav>
  );
}

export function NavbarList({ children, className = "", ...props }) {
  children = Children.toArray(children).filter(Boolean);
  return (
    <ul className={clsx("flex space-x-2", className)} {...props}>
      {children.map((child, id) => (
        <li className="flex flex-grow items-center" key={id}>
          {child}
        </li>
      ))}
    </ul>
  );
}
