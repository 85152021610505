import Head from "next/head";
import Link from "next/link";
import { Navbar, NavbarList } from "~/components/navbar";
import threadIcon from "~/assets/thread.svg";

export default function LandingPage() {
  return (
    <>
      <Head>
        <title>Threads</title>
        <meta
          name="description"
          content="String together trains-of-thought. Threads is a social notetaking app. Write, relate, and then share your ideas — all within a personal wiki."
        />
        <meta property="og:title" content="Threads" />
        <meta
          property="og:description"
          content="String together trains-of-thought. Threads is a social notetaking app. Write, relate, and then share your ideas — all within a personal wiki."
        />
      </Head>
      <div className="min-h-screen w-full flex flex-col items-center">
        <Navbar className="max-w-7xl py-6 px-4 md:px-6">
          <NavbarList>
            <Link
              href="/"
              className="flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-sky-300 rounded-sm"
              title="threads"
            >
              <img className="h-6 w-auto inline" src={threadIcon} alt="home" />
              <span className="font-bold text-black text-base tracking-tight">
                threads
              </span>
            </Link>
          </NavbarList>
          <NavbarList>
            <Link
              href="/explore"
              className="font-medium text-sm px-1 no-underline hover:underline focus:outline-none focus-visible:ring-2 focus-visible:ring-sky-300 rounded-sm"
            >
              Explore
            </Link>
            {/* <Link href="/signup">
            <a className="font-medium text-sm px-1 no-underline hover:underline focus:outline-none focus-visible:ring-2 focus-visible:ring-sky-300 rounded-sm">
              Sign up
            </a>
          </Link> */}
            <Link
              href="/login"
              className="font-medium text-sm px-1 no-underline hover:underline focus:outline-none focus-visible:ring-2 focus-visible:ring-sky-300 rounded-sm"
            >
              Login
            </Link>
          </NavbarList>
        </Navbar>
        <div className="flex-grow flex flex-col">
          <main className="flex-grow flex flex-col mt-16 mx-auto max-w-7xl px-4 sm:mt-24 text-center">
            <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-5xl">
              String together trains-of-thought
            </h1>
            <h2 className="mt-4 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:text-xl md:max-w-5xl">
              Threads is a social notetaking app. Write, relate, and then share
              your ideas — all within a personal wiki.
            </h2>
          </main>
        </div>
      </div>
    </>
  );
}
